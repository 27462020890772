<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <DIDListCard
                    :loading="loading"
                    :small="$vuetify.breakpoint.xsOnly"
                    :filter="filter"
                    @update="updateFilter"
                    :addFunction="openAddDialog"
                    show-filters
                    show-tools
                />
            </v-col>
        </v-row>
        <FloatingButton tooltip="Add New DIDs" icon="mdi-phone-plus" @click="openAddDialog" />
        <AddDIDDialogFromManualEntry v-if="$store.state.user.isAdmin" v-model="showDialogFromManualEntry" process-type-switch="pool" />
    </v-container>
</template>

<script>
import DIDListCard from "../../components/cards/DID/DIDListCard.vue";
import filterMixin from '../../mixins/filterMixin';
import FloatingButton from '@/components/pieces/FloatingButton';
import AddDIDDialogTypeSelect from "../../components/dialogs/DID/AddDIDDialogTypeSelect.vue";
import AddDIDDialogFromManualEntry from "../../components/dialogs/DID/AddDIDDialogFromManualEntry.vue";
export default {
    name: 'DIDInventory',
    mixins: [filterMixin],
    data: () => ({
        filter: {
            usage: 'A',
            number: null,
            page: 1,
            limit: 10,
        },
        filterInput: {
            number: '',
        },
        loading: false,
        // addDidOptions: {"admin":[2,3]}
        showDialogTypeSelect: false,
        showDialogFromCarrier: false,
        showDialogFromManualEntry: false,
    }),
    components: {
       AddDIDDialogFromManualEntry,
        FloatingButton,
        DIDListCard,
    },
    beforeRouteLeave(to, from, next){
        return (this.showNewDIDs) ? next(false) : next();
    },
  methods:{
    openAddDialog: function( ){
      if(this.$store.state.user.isAdmin){
        this.showDialogFromManualEntry=true
      }//else users cannot add to the did inventory
    },
  }
};
</script>
