<template>
    <v-container fluid>
        <v-row dense>
            <!-- non-dismissable alert only shows if status is blocked -->
            <v-col cols="12" v-if="data.frozen === 'Y'">
                <Alert type="error" message="This number is frozen." />
            </v-col>
            <!-- DID Details Card -->
            <v-col cols="12" sm="6" lg="4">
                <DIDCard :data="data" :loading="loading" />
            </v-col>
            <!-- DID Assigment Card -->
            <v-col cols="12" sm="6" lg="4" v-if="data.i_master_account">
                <DIDAssignmentCard
                    :account-id="data.i_master_account"
                    :loading="loading"
                />
            </v-col>
            <v-col cols="12" sm="6" lg="4">
                <DIDPricing :data="data" :loading="loading" />
            </v-col>
            <v-col cols="12" sm="6" lg="4">
                <DIDCarrierCard :data="data" :loading="loading" />
            </v-col>
            <v-col cols="12" sm="6" lg="4" v-if="data.ipnd_details != null">
              <IPNDCard :ipnd-data="data.ipnd_details" card-type="ipnd"/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Alert from '@/components/pieces/Alert';
import DIDCard from '../../components/cards/DID/DIDCard';
import DIDAssignmentCard from '../../components/cards/DID/DIDAssignmentCard';
import DIDPricing from '../../components/cards/DID/DIDPricing';
import DIDCarrierCard from '../../components/cards/DID/DIDCarrierCard';
import apiMixin from '../../mixins/apiMixin';
import IPNDCard from "../../components/cards/IPND/IPNDCard.vue";

export default {
    name: 'DIDDetail',
    mixins: [apiMixin],
    data: () => ({
        loading: true,
        reloadDIDList: false,
        newDIDAdded: false,
        newDIDAddedSuccess: false,
        data: {
            data: [],
        },
        dialogs: {
            newDID: false,
        },
        portaAccountData: null,
    }),
    components: {
        IPNDCard,
        DIDCarrierCard,
        DIDPricing,
        DIDAssignmentCard,
        DIDCard,
        Alert,
    },
    computed: {
        addDIDMessage: function () {
            return this.newDIDAdded && this.newDIDAddedSuccess
                ? 'DIDs are added successfully.'
                : 'There is an error while adding new DIDs. Please try again later.';
        },
    },
    methods: {
        async get(did) {
            this.loading = true;
            const response = await this.Api.send('post','dids/' + did + '/info');
            this.loading = false;
            if (response.success) {
                this.updateData(response.data);
            } else {
                this.updateFailed(response.error, response.errorDetail);
            }

            //for the IPND logic we need the PortaAccount. We load these details here.
            this.portaAccountData = null;
            if(this.data.i_account != null){
              //load porta account details

            }
        },
        update() {
            this.get(this.$route.params.did);
        },
        updateData(data) {
            this.data = data;
        },
        updateFailed() {
            this.data = [];
        },
        savedDID() {
            this.dialogs.newDID = false;
            this.newDIDAdded = true;
            this.newDIDAddedSuccess = true;
            this.reloadDIDList = true;
            this.$nextTick(() => {
                this.reloadDIDList = false;
            });
        },
        addDIDError() {
            this.newDIDAdded = true;
            this.newDIDAddedSuccess = false;
        },
    },
    created() {
        this.update();
    },
};
</script>
